<!-- eslint-disable vue/no-v-html -->
<template>
  <span v-html="t(props.name, props.domain, props.variables)" />
</template>
<!-- eslint-enable vue/no-v-html -->

<script setup lang="ts">
const props = defineProps({
  domain: {
    type: String,
    default: 't'
  },
  name: {
    type: String,
    default: null
  },
  variables: {
    type: Object,
    default: null
  }
})
const t = useTranslations(`${props.domain}`).translate

</script>
